import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Layout } from "../components"

const PolitiqueDeConfidentialite = () => {
  return (
    <Layout>
      <Seo title="Koomet - Politique de confidentialité"
      desc="Politique de confidentialité de l'agence web Koomet basée à Bourges et Saint-Amand-Montrond."
      breadcrumb
      bread1name="Accueil"
      bread1url="/"
      bread2name="Politique de Confidentialité"
      bread2url="/politique-de-confidentialite/"
      />
      <div className="flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center text-3xl font-extrabold text-gray-700 pb-10 dark:text-white">
              <h1>Politique de Confidentialité</h1>
            </div>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">1. Gestion des données personnelles.</h2>
            <p>Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-md">1.1 Responsables de la collecte des données personnelles</h2>
            <p>Pour les Données Personnelles collectées dans le cadre de la navigation de l’Utilisateur sur le Site www.koomet.fr, le responsable du traitement des Données Personnelles est : Nicolas LEZZI. www.koomet.fr est représenté par Nicolas LEZZI, son représentant légal</p>
            <p className="mt-3">En tant que responsable du traitement des données qu’il collecte, www.koomet.fr s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité. Chaque fois que www.koomet.fr traite des Données Personnelles, www.koomet.fr prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles www.koomet.fr les traite.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-md">1.2 Finalité des données collectées</h2>
            <p className="mb-3">www.koomet.fr est susceptible de traiter tout ou partie des données :</p>
            <ul className="list-disc pl-10">
              <li>pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données de connexion et d’utilisation du Site, facturation etc.</li>
              <li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP</li>
              <li>pour améliorer la navigation sur le Site : données de connexion et d’utilisation</li>
              <li>pour mener des campagnes de communication (sms, mail) : numéro de téléphone, adresse email</li>
            </ul>
            <p className="mt-3">www.koomet.fr ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-md">1.3 Droit d'accès, de rectification et d'opposition</h2>
            <p className="mb-3">Conformément à la réglementation européenne en vigueur, les Utilisateurs de www.koomet.fr disposent des droits suivants :</p>
            <ul className="list-disc pl-10">
              <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
              <li>droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
              <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)</li>
              <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
              <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
              <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui www.koomet.fr devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
            </ul>
            <p className="mt-3">Dès que www.koomet.fr a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, www.koomet.fr s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.</p>
            <p>Si l’Utilisateur souhaite savoir comment www.koomet.fr utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter www.koomet.fr par écrit directement depuis notre <AniLink className="text-violet" cover bg="#6377E9" direction="up" duration={0.6} to="/contact/">formulaire de contact</AniLink>.</p>
            <p>Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que www.koomet.fr corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).</p>
            <p>Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à www.koomet.fr par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de www.koomet.fr peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-md">1.4 Non-communication des données personnelles</h2>
            <p>www.koomet.fr s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, www.koomet.fr reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
            <p className="mt-3">www.koomet.fr s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de www.koomet.fr, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs www.koomet.fr ne collecte aucune « données sensibles ».</p>
            <p className="mt-3">Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de www.koomet.fr et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.</p>
            <p className="mt-3">Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de www.koomet.fr sont principalement les agents de notre service client.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">2. Notification d'incident.</h2>
            <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.</p>
            <p className="mt-3">Aucune information personnelle de l'utilisateur du site www.koomet.fr n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de www.koomet.fr et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site www.koomet.fr.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-md">Sécurité</h2>
            <p>Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données Personnelles de Santé, www.koomet.fr utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, l’encryption (SSL).</p>
            <p className="mt-3">Lors du traitement des Données Personnelles, www.koomet.fr prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">3. Liens hypertextes.</h2>
            <p>Le site www.koomet.fr contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de www.koomet.fr. Cependant, www.koomet.fr n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">4. Les "cookies".</h2>
            <p>Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.</p>
            <p className="mt-3">www.koomet.fr est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, le temps de visite. Ces informations permettent à www.koomet.fr d’améliorer le contenu du Site, de la navigation de l’Utilisateur.</p>
            <p className="mt-3">Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. www.koomet.fr informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p>
            <p className="mt-3">À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. www.koomet.fr pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p>
            <p className="mt-3">Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Facebook et Twitter figurant sur le Site de www.koomet.fr ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de www.koomet.fr, Twitter et Facebook peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p>
            <p className="mt-3">Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de www.koomet.fr. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que www.koomet.fr dépose ce type de cookies grâce à un module fourni par <strong>Axeptio</strong> (module jaune en bas à gauche).</p>
            <h2 className="text-xl mt-5 mb-5 font-extrabold text-violet sm:text-2xl">5. Droit applicable et juridiction compétente.</h2>
            <p>Tout litige en relation avec l’utilisation du site www.koomet.fr est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default PolitiqueDeConfidentialite
